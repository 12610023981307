import { ProjectAuthType } from "@prisma/client";
import MaintenancePageComponent from "components/MaintenanceComponent";
import { AuthPage } from "components/ProjectAuth/AuthPage";
import { PublishedPage } from "components/PublishedPageSSR";
import PublishedProvider from "components/PublishedProvider";
import { VerifyBrowser } from "components/VerifyBrowser";
import { RecaptchaProvider } from "lib/hooks/RecaptchaProvider";
import { setProjectSession } from "lib/project-sessions/project-session-storage";
import type { InterfacesTheme } from "lib/theme/ThemeProvider";
import { GetServerSideProps } from "next";
import { isAuthenticatedForProject } from "server/auth";
import { getMaintenanceMode } from "@/config/edge";
import { prisma } from "server/data/prisma";
import { withProjectSessionSSR } from "server/hooks/withProjectSession";
import { withRequestContextGetServerSideProps } from "server/hooks/withRequestContext";
import { PageContentInclude } from "server/models/blocks";
import {
  getPageWithBlocksForProject,
  toPageWithContentValidated,
  getLoginPageForProject,
  toPageWithContent,
} from "server/models/pages";
import { getProjectFromSlugOrCustomDomain } from "server/models/projects";
import { getThemeByProjectId } from "server/models/theme";
import logger from "server/observability/logger";
import { getConsumerByProjectSessionData } from "server/operations/consumers/getConsumerByProjectSessionData";
import { meConsumerSchema } from "server/schemas/consumers";
import { pagePublicSchema } from "server/schemas/pages";
import {
  ConsumerProject,
  consumerProjectSchema,
  projectForPublishedPageSchema,
} from "server/schemas/projects";
import { getFeatureFlags } from "server/services/split/split";
import { AppError } from "server/types/errors";
import { SplitCheck, trpc } from "utils/trpc";
import { z } from "zod";
import type { ProjectSessionData } from "../../server/sessions/project";
import { PagePublicSchema } from "@/server/schemas/pages";

type Params = { publishedPageParams: [string, string] };

export type ProjectForPublishedPage = z.infer<
  typeof projectForPublishedPageSchema
>;

type PageForPublishedLoginPage = { id: PagePublicSchema["id"] };

type PageForCaptchaVerificationPage = { id: PagePublicSchema["id"] };

type MeConsumer = z.infer<typeof meConsumerSchema>;

type RenderMaintenanceModeProps = {
  maintenanceMode: true;
};

type RenderBrowserVerificationProps = {
  maintenanceMode: false;
  verifyBrowser: true;
  sessionId: string;
  page: PageForCaptchaVerificationPage;
};

type RenderAuthFormProps = {
  maintenanceMode: false;
  verifyBrowser?: boolean;
  authorized: false;
  sessionId: string;
  project: ConsumerProject;
  page: PageForPublishedLoginPage;
  loginPage: PagePublicSchema | null;
  splitCheck: SplitCheck;
  theme: InterfacesTheme;
  builderFlags: SplitCheck;
};

export type RenderPageProps = {
  maintenanceMode: false;
  authorized: true;
  sessionId: string;
  project: ProjectForPublishedPage;
  page: PagePublicSchema;
  loginPage: PagePublicSchema | null;
  allPages: PagePublicSchema[];
  consumer: MeConsumer | null;
  verifyBrowser: false;
  splitCheck: SplitCheck;
  theme: InterfacesTheme;
  builderFlags: SplitCheck;
};

type Props =
  | RenderMaintenanceModeProps
  | RenderBrowserVerificationProps
  | RenderAuthFormProps
  | RenderPageProps;

export default function PublishedPageNextJSPage(props: Props) {
  const utils = trpc.useUtils();

  if (props.maintenanceMode) {
    return <MaintenancePageComponent />;
  }

  setProjectSession({
    sessionId: props.sessionId,
    pageId: "page" in props ? props.page.id : undefined,
  });

  if (props.verifyBrowser) {
    return (
      <RecaptchaProvider>
        <VerifyBrowser onSuccess={() => window.location.reload()} />
      </RecaptchaProvider>
    );
  }

  utils.split.check.setData({ projectId: props.project.id }, props.splitCheck);

  if ("authorized" in props && !props.authorized) {
    return (
      <PublishedProvider
        project={props.project}
        interfacesTheme={props.theme}
        builderFlags={props.builderFlags}
      >
        <RecaptchaProvider>
          <AuthPage
            project={props.project}
            loginPage={props.loginPage}
            refetch={() => window.location.reload()}
          />
        </RecaptchaProvider>
      </PublishedProvider>
    );
  }

  const { project, page, allPages, consumer, theme, builderFlags } = props;

  // TODO INTRFCS-2342
  // Hydrate the react-query client cache.
  //
  // This is a bit of a hack. We're using setData to hydrate instead of using
  // the [established trpc helpers](https://trpc.io/docs/client/nextjs/server-side-helpers#nextjs-example)
  // to hydrate the cache. I tried them a long time ago in a POC, and cannot
  // remember why they didn't work. I will be revisiting this to try them
  // again, and document why they fail if they do. In the meantime, this
  // works.

  if (!utils.projects.get.getData({ id: project.id })) {
    // @ts-ignore
    utils.projects.get.setData({ id: project.id }, project);
  }

  if (page && !utils.pages.get.getData({ id: page.id })) {
    utils.pages.get.setData({ id: page.id }, page);
  }

  if (allPages && !utils.pages.list.getData({ projectId: project.id })) {
    utils.pages.list.setData({ projectId: project.id }, allPages);
  }

  if (consumer && !utils.consumers.me.getData({ projectId: project.id })) {
    utils.consumers.me.setData({ projectId: project.id }, consumer);
  }

  return (
    <PublishedProvider
      project={project}
      interfacesTheme={theme}
      builderFlags={builderFlags}
    >
      <RecaptchaProvider>
        <PublishedPage isEmbed={false} project={project} page={page} />
      </RecaptchaProvider>
    </PublishedProvider>
  );
}

export const getServerSideProps: GetServerSideProps<Props, Params> =
  withRequestContextGetServerSideProps<Props, Params>(
    withProjectSessionSSR<Props, Params>(async ({ params, req }) => {
      const log = logger().child(
        { params, session: req.projectSession.getLoggerSafeData() },
        { msgPrefix: "Published Page GSSP: " }
      );

      if (await getMaintenanceMode()) {
        log.warn("Maintenance mode is enabled");

        return {
          props: {
            maintenanceMode: true,
          },
        };
      }

      if (!params?.publishedPageParams) {
        log.warn("No publishedPageParams provided");

        return {
          notFound: true,
        };
      }

      const [projectSlugOrCustomDomain, pageSlug] = params.publishedPageParams;
      if (!projectSlugOrCustomDomain) {
        log.warn("No projectSlugOrCustomDomain provided");

        return {
          notFound: true,
        };
      }

      const project = await getProjectFromSlugOrCustomDomain(
        projectSlugOrCustomDomain
      );

      log.debug("Got project", {
        projectId: project?.id,
        projectAuthType: project?.authType,
      });

      if (!project) {
        return {
          notFound: true,
        };
      }

      const theme = await getThemeByProjectId(project.id);

      log.debug("Got theme");

      const currentPage = await getPageWithBlocksForProject({
        pageSlug,
        projectId: project.id,
        homepageId: project.homepageId,
      });

      log.debug("Got current page", {
        pageId: currentPage?.id,
        projectId: project.id,
      });

      if (!currentPage) {
        return {
          notFound: true,
        };
      }

      const loginPage = await getParsedLoginPage({
        projectId: project.id,
        loginPageId: project.loginPageId,
      });

      const splitCheck = await getFeatureFlags({
        zapierUser: undefined,
        project,
      });

      const builderFlags = await getFeatureFlags({
        zapierUser: project.creator,
        project,
      });

      log.debug("Got split check", { splitCheck });

      const isAuthenticated = await isAuthenticatedForProject({
        project,
        session: req.projectSession,
      });

      log.debug("Is authenticated", { isAuthenticated });

      if (!isAuthenticated) {
        req.projectSession.clearAuthData();

        req.projectSession.data.enableChatbot = false;
        req.projectSession.data.projectId = project.id;

        log.warn("Not authenticated. Changing session", {
          newSessionData: req.projectSession.getLoggerSafeData(),
        });

        await req.projectSession.save();

        return {
          props: {
            maintenanceMode: false,
            verifyBrowser: false,
            authorized: false,
            project: consumerProjectSchema.parse(project),
            page: { id: currentPage.id },
            loginPage,
            sessionId: req.projectSession.id,
            splitCheck,
            builderFlags,
            theme,
          },
        };
      }

      if (currentPage.id === project.loginPageId) {
        return {
          redirect: {
            destination: "/",
            permanent: false,
          },
        };
      }

      const displayCaptcha =
        project.captchaEnabled &&
        project.authType === ProjectAuthType.None &&
        !req.projectSession.data.captchaTokenProperties?.valid;

      if (displayCaptcha) {
        log.debug("Displaying captcha verification page");

        req.projectSession.data = {
          ...req.projectSession.data,
          enableChatbot: false,
          projectId: project.id,
        };
        await req.projectSession.save();

        return {
          props: {
            maintenanceMode: false,
            verifyBrowser: true,
            sessionId: req.projectSession.id,
            page: { id: currentPage.id },
          },
        };
      }

      const allPages = await prisma.page
        .findMany({
          where: { projectId: project.id },
          include: PageContentInclude,
        })
        .then((pages) => {
          return pages.map(toPageWithContent);
        });

      log.debug("Got all pages", { pagesIds: allPages.map((p) => p.id) });

      // If the page contains a chatbot block, enable the chatbot for this session
      const enableChatbot = currentPage?.blocks.some(
        (blockOnPage) => blockOnPage.type === "Chatbot"
      );

      log.debug("Chatbot enabled", { enableChatbot });

      const consumer = await getConsumer(req.projectSession.data);

      log.debug("Got consumer", { consumerId: consumer?.id });

      req.projectSession.data = {
        ...req.projectSession.data,
        enableChatbot,
        projectId: project.id,
      };

      log.debug("Updated session data", {
        newSessionData: req.projectSession.getLoggerSafeData(),
      });

      await req.projectSession.save();

      log.debug("Validating page content");
      const validatedPage = await toPageWithContentValidated(
        currentPage,
        project,
        req.projectSession.id
      );
      log.debug("Finished validating page content");

      return {
        props: {
          project: projectForPublishedPageSchema.parse(project),
          page: pagePublicSchema.parse(validatedPage),
          loginPage,
          allPages: pagePublicSchema.array().parse(allPages),
          consumer,
          sessionId: req.projectSession.id,
          authorized: true,
          verifyBrowser: false,
          maintenanceMode: false,
          splitCheck,
          builderFlags,
          theme,
        },
      };
    })
  );

async function getConsumer(projectSessionData: ProjectSessionData) {
  try {
    return await getConsumerByProjectSessionData(projectSessionData);
  } catch (error) {
    const isAppError = error instanceof AppError;
    if (!isAppError) {
      throw error;
    }

    const isRecoverableError =
      error.code === "UNAUTHORIZED" || error.code === "NOT_FOUND";
    if (isRecoverableError) {
      return null;
    }

    throw error;
  }
}

async function getParsedLoginPage({
  projectId,
  loginPageId,
}: {
  projectId: string;
  loginPageId: string | null;
}): Promise<PagePublicSchema | null> {
  if (!loginPageId) {
    return null;
  }

  const loginPage = await getLoginPageForProject({ projectId });

  if (!loginPage) {
    return null;
  }

  return pagePublicSchema.parse(loginPage);
}
