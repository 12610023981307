import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import React from "react";
import { cn } from "utils/cn";

export const ProjectAuthWrapper = (
  props: React.ComponentPropsWithoutRef<"div">
) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "min-h-screen",
        interfacesTheme ? "bg-background" : "bg-zi-pageBackground",
        props.className
      )}
    />
  );
};
