import { Spinner } from "./Spinner";

export function PublishedPageSpinner({
  ariaLabelSuffix = "",
}: {
  ariaLabelSuffix?: string;
}) {
  return (
    <div className="flex min-h-screen w-full items-center justify-center">
      <Spinner
        size="medium"
        ariaLabel={`Loading... ${ariaLabelSuffix}`.trim()}
      />
    </div>
  );
}
