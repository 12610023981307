import { useExecuteRecaptcha } from "lib/hooks/RecaptchaProvider";
import { useEffect, useState } from "react";
import { trpc } from "utils/trpc";
import { ReCAPTCHA } from "./ReCAPTCHA";

const STEPS = ["hidden_captcha", "rendered_captcha", "failure"] as const;

const WrapperClassName =
  "flex h-screen w-screen flex-col items-center justify-center";

type OnChange = (token: string) => void;

type Props = {
  onSuccess: () => void;
};

// This component is used to verify a consumer of a published page is a human.
export const VerifyBrowser = ({ onSuccess }: Props) => {
  const [stepI, setStepI] = useState<number>(0);
  const step = STEPS[stepI];
  const nextStep = () => setStepI((i) => i + 1);

  const { mutate: verifyBrowser, isError: isMutationError } =
    trpc.projectAuth.verifyBrowser.useMutation();

  const handleChange: OnChange = (token) => {
    verifyBrowser(
      {
        token,
        isHidden: step === "hidden_captcha",
      },
      {
        onSuccess: (data) => {
          if (data.success) {
            onSuccess();
          } else {
            nextStep();
          }
        },
      }
    );
  };

  if (isMutationError) {
    return (
      <div className={WrapperClassName}>
        <div className="m-7 text-center text-red-700">
          <div className="text-xl">Uh oh, somethihng went wrong.</div>
          <div className="text-md mt-1">Please try reloading the page.</div>
        </div>
      </div>
    );
  }

  // This component has three steps:
  // 1. Hidden captcha: This step is used to verify the browser without showing
  //    the captcha to the user. If this step succeeds, we stop here. The page is
  //    reloaded and the page content is rendered. Otherwise, we move to the next
  //    step.
  // 2. Rendered captcha: Since the hidden captcha failed, we render a captcha so
  //    the user can prove their skills at recognizing motorcycles and buses.
  // 3. Failure: If the user fails the captcha, we show a failure message.
  switch (step) {
    case "hidden_captcha": {
      return <HiddenCaptcha onChange={handleChange} />;
    }

    case "rendered_captcha": {
      return (
        <div className={WrapperClassName}>
          <ReCAPTCHA action="LOAD_PROJECT" onChange={handleChange} />
        </div>
      );
    }

    case "failure": {
      return (
        <div className={WrapperClassName}>
          <div className="m-7 text-xl text-red-700">Verification failed</div>
        </div>
      );
    }

    default:
      const _exhaustiveCheck: never = step;
      return _exhaustiveCheck;
  }
};

function HiddenCaptcha({ onChange }: { onChange: OnChange }) {
  const { generate } = useExecuteRecaptcha();
  const [hasExecuted, setHasExecuted] = useState(false);

  useEffect(() => {
    if (hasExecuted) return;
    setHasExecuted(true);

    generate({
      action: "LOAD_PROJECT",
      onSuccess: onChange,
    });
  }, [generate, hasExecuted, onChange]);

  return (
    <div className={WrapperClassName}>
      <div className="m-7 text-xl text-sky-700">Verifying your browser</div>
      <div className="h-5 w-5 animate-ping rounded-full bg-sky-700" />
    </div>
  );
}
