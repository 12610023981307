import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

export function ProjectError({
  title,
  message,
}: {
  title: string;
  message: string;
}) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      className={cn(
        "flex min-h-screen w-full flex-col items-center justify-center gap-5 font-sans",
        interfacesTheme
          ? "bg-background text-muted-foreground"
          : "bg-zi-pageBackground text-zi-secondaryText"
      )}
    >
      <h1 className="text-[42px] font-semibold leading-[50px]">{title}</h1>
      <p className="mt-4 max-w-[800px] text-[24px] font-normal leading-[40px]">
        {message}
      </p>
    </div>
  );
}
